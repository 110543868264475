<link rel="stylesheet" href="/styles/vendor/splide-core.min.css">

<style lang="scss">
  @media screen and (min-width: 1024px) {
    .splide__slide.one-whole { width: 100%; }
    .splide__slide.one-half { width: calc((100% - 20px ) / 2); }
    .splide__slide.one-third { width: calc((100% - 20px * 2 ) / 3); }
  }

  @media screen and (min-width: 750px) and (max-width: 1023px) {
    .splide__slide.one-whole { width: 100%; }
    .splide__slide.one-half { width: calc((100% - 15px ) / 2); }
    .splide__slide.one-third { width: calc((100% - 15px * 2 ) / 3); }
  }

  .max-h-full {
    max-height: calc(100vh - var(--header-height));
  }
</style>

<script>
  export let sliderNewsData=[]
  export let componentData = null;
  export let dividerAbove = false;
  export let dividerBelow = false

  function getLocalePrefix(locale) {
    switch(locale) {
      case 'sv': return '/erbjudanden/';
      case 'en': return '/en/offers/';
      default: return '/undefined-locale/';
    }
  }

  function convertToURLSlug(slug, id = null) {
    slug = handleSpecialCharacters(slug);
    if(id !== null) {
      slug = `${slug}-${id}`;
    }
    return slug.split(' ').map(function(word){
      return word.toLowerCase();
    }).join('-').replace(/[^a-zA-Z0-9-]/g, '');
  }

  function handleSpecialCharacters(str) {
    let map = {
      'å': 'a',
      'ä': 'a',
      'ö': 'o',
      // option to add more special characters here
    };
    return str.replace(/[åäö]/g, function(match) {
      return map[match];
    });
  }

  import {onMount} from 'svelte'
  import Splide from '@splidejs/splide';

  let slider;


  let old_sliderNewsData = [
    {
      imgSrc: '../../src/assets/myimg.png',
      subheading: 'NYHET!',
      title: 'Kontor för husse <br>och matte!',
      text: '<p>7A:s hundvänliga citykontor ligger placerade på Odenplan, Strandvägen samt Vasagatan.</p>',
      btnLink: '#',
      btnText: 'Läs mer'
    },
    {
      imgSrc: '../../src/assets/myimg.png',
      subheading: 'NYHET',
      title: 'Kontor för husse <br>och matte!',
      text: '<p>7A:s hundvänliga citykontor ligger placerade på Odenplan, Strandvägen samt Vasagatan.</p>',
      btnLink: '#',
      btnText: 'Läs mer'
    },
    {
      imgSrc: '../../src/assets/myimg.png',
      subheading: 'NYHET',
      title: 'Kontor för husse <br>och matte!',
      text: '<p>7A:s hundvänliga citykontor ligger placerade på Odenplan, Strandvägen samt Vasagatan.</p>',
      btnLink: '#',
      btnText: 'Läs mer'
    },
    {
      imgSrc: '../../src/assets/myimg.png',
      subheading: 'NYHET',
      title: 'Kontor för husse <br>och matte!',
      text: '<p>7A:s hundvänliga citykontor ligger placerade på Odenplan, Strandvägen samt Vasagatan.</p>',
      btnLink: '#',
      btnText: 'Läs mer'
    },
    {
      imgSrc: '../../src/assets/myimg.png',
      subheading: 'NYHET',
      title: 'Kontor för husse <br>och matte!',
      text: '<p>7A:s hundvänliga citykontor ligger placerade på Odenplan, Strandvägen samt Vasagatan.</p>',
      btnLink: '#',
      btnText: 'Läs mer'
    }
  ];

  const BASE_URL = import.meta.env.PUBLIC_BASE_URL;
const BASE_SELF_URL = import.meta.env.PUBLIC_BASE_SELF_URL;

  if(componentData){
    let pageId = componentData.pageId
    let idExists
    let slidesData = componentData.Erbjudanden.data;
    let slugParsed = '';
    let locale;
    let prefixdURL;

    sliderNewsData = slidesData
            .filter(item => {
              let pagesData = item.attributes.pages.data;
              idExists = pagesData.some(page => page.id === pageId);
              return idExists;
            })
            .map(item => {
              slugParsed = convertToURLSlug(item.attributes.Title, item.id)
              locale = item.attributes.locale


              return {
                imgSrc: item.attributes.CoverImage?.data?.attributes?.url ? BASE_URL + item.attributes.CoverImage.data.attributes.url : '',
                subheading: item.attributes.Category ? item.attributes.Category : '',
                title: item.attributes.Title,
                text: item.attributes.Description,
                btnLink: getLocalePrefix(locale) + slugParsed,
                btnText: item.attributes.Button_Text
              };
            });
  }



  onMount(() => {
    const sliderNews = new Splide(slider, {
      pagination: false,
      autoWidth: true,
      drag: true,
      perMove: 2,
      gap: 20,
      breakpoints: {
        1023: {
          gap: 15,
        },
      }
    });

    sliderNews.mount();
  });

</script>

<section class="section"
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>
  <div class="wrapper">
    <div class="splide__outer">
      <div class="splide" bind:this={slider}>

        <!-- Slider custom arrows -->
        <div
          class="splide__arrows"
          class:desktop-hidden={sliderNewsData.length <= 3}
        >
          <button class="splide__arrow splide__arrow--prev">
            <span class="visually-hidden">Prev</span>
            <svg class="icon icon-arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
              <polygon points="28.4 16.5 17.59 27.29 19.89 29.59 33 16.5 19.89 3.41 17.59 5.71 28.4 16.5"/>
              <polyline points="28.62 14.88 0 14.87 0 18.12 28.62 18.13"/>
            </svg>
          </button>
          <button class="splide__arrow splide__arrow--next">
            <span class="visually-hidden">Next</span>
            <svg class="icon icon-arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
              <polygon points="28.4 16.5 17.59 27.29 19.89 29.59 33 16.5 19.89 3.41 17.59 5.71 28.4 16.5"/>
              <polyline points="28.62 14.88 0 14.87 0 18.12 28.62 18.13"/>
            </svg>
          </button>
        </div>

        <!-- Slider items -->
        <div class="splide__track">
          <ul class="splide__list">
            {#each sliderNewsData as item}
              <li class="splide__slide four-fifths--mobile max-h-full"
                class:one-whole={sliderNewsData.length == 1}
                class:one-half={sliderNewsData.length == 2}
                class:one-third={sliderNewsData.length == 3}
                class:two-fifths={sliderNewsData.length > 3}
              >
                <a href={item.btnLink} class="post-card">
                  {#if item.subheading}
                    <h6>{item.subheading}</h6>
                  {/if}
                  
                  <div class="post-card__bg">
                    <img width="1920" height="1080" src={item.imgSrc} alt="{item.title}">
                  </div>
                  <div class="post-card__content max-h-full">  
                    {#if item.title}
                      <h2>{@html item.title}</h2>
                    {/if}

                    {#if item.text}
                      <p>{@html item.text}</p>
                    {/if}
                    {#if item.btnText}
                      <span class="btn btn--outline">{item.btnText}</span>
                    {/if}
                  </div>
                </a>
              </li>
            {/each}
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
